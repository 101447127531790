
// rates source
//https://reiwa.com.au/the-wa-market/resources/calculators/stamp-duty-calculator/

import {
    BUILDING_TYPE,
    VACANT_LAND,
    NEW_BUILDING,
    EXISTING_BUILDING,
    FIRST_HOME_OWNER,
    PRIMARY_RESIDENCE ,
    FIRST_HOME_OWNER_GRANT,
    FOREIGN_INVESTOR,

    STATE_ACT,
    STATE_NSW,
    STATE_NT,
    STATE_QLD,
    STATE_SA,
    STATE_TAS,
    STATE_VIC,
    STATE_NOT_APPLICABLE,
    STATE_WA,

} from './calculatorConstants';
    
    
export const data = {
    options: [
        {
            state: STATE_NOT_APPLICABLE,
            options: []
        },

        {
            state: STATE_ACT,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [],
                },
                // {
                //     label:FIRST_HOME_OWNER_GRANT,
                //     conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                //     exclusions: [],
                // }
            ]
        },
        {
            state: STATE_NSW,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [],
                },
                {
                    label:FIRST_HOME_OWNER_GRANT,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                    exclusions: [],
                }
            ]
        },
        {
            state: STATE_NT,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER_GRANT,
                    conditions: [PRIMARY_RESIDENCE],
                    //exclusions: [ {buildingType: EXISTING_BUILDING}],
                }
            ]
        },
        {
            state: STATE_QLD,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                FOREIGN_INVESTOR,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [FOREIGN_INVESTOR, {buildingType: VACANT_LAND}],
                },
                {
                    label:FIRST_HOME_OWNER_GRANT,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                    exclusions: [FOREIGN_INVESTOR, {buildingType: EXISTING_BUILDING}],
                }
            ]
        },
        {
            state: STATE_SA,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER_GRANT,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [ {buildingType: EXISTING_BUILDING}],
                }
            ]
        },
        {
            state: STATE_TAS,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [],
                }
            ]
        },
        {
            state: STATE_WA,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    //exclusions: [ {buildingType: EXISTING_BUILDING}],
                }
            ]
        },
        {
            state: STATE_VIC,
            options: [
                BUILDING_TYPE,
                PRIMARY_RESIDENCE,
                {
                    label:FIRST_HOME_OWNER,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [ {buildingType: EXISTING_BUILDING}],
                },
                {
                    label:"metropolitanProperty",
                    conditions: [ {buildingType: NEW_BUILDING}],
                    exclusions: [],
                },
            ]
        },

    ],

    lmi: {
        threshold: 80.0,
        rates:
            [
                {
                    // 80 - 85
                    lvr: 85.0, rates: [
                        { state: 'NSW', low: 0.49, high: 1.19 },
                        { state: 'VIC', low: 0.62, high: 1.51 },
                        { state: 'QLD', low: 0.61, high: 1.19 },
                        { state: 'ACT', low: 0.61, high: 1.11 },
                        { state: 'TAS', low: 0.71, high: 1.42 },
                        { state: 'SA', low: 0.73, high: 1.71 },
                        { state: 'NT', low: 0.72, high: 1.52 },
                        { state: 'WA', low: 0.71, high: 1.51 },
                    ]
                },

                {
                    // 85 - 90
                    lvr: 90.0, rates: [
                        { state: 'NSW', low: 1.21, high: 2.21 },
                        { state: 'VIC', low: 1.31, high: 3.61 },
                        { state: 'QLD', low: 1.31, high: 2.21 },
                        { state: 'ACT', low: 1.11, high: 2.01 },
                        { state: 'TAS', low: 1.31, high: 2.41 },
                        { state: 'SA', low: 1.31, high: 3.81 },
                        { state: 'NT', low: 1.31, high: 3.61 },
                        { state: 'WA', low: 1.31, high: 2.41 },
                    ]
                },

                {
                    // 90 - 95
                    lvr: 95.0, rates: [
                        { state: 'NSW', low: 2.21, high: 3.71 },
                        { state: 'VIC', low: 2.51, high: 4.21 },
                        { state: 'QLD', low: 2.51, high: 3.01 },
                        { state: 'ACT', low: 2.51, high: 3.31 },
                        { state: 'TAS', low: 2.41, high: 4.21 },
                        { state: 'SA', low: 2.61, high: 4.81 },
                        { state: 'NT', low: 2.51, high: 4.91 },
                        { state: 'WA', low: 2.51, high: 4.21 },
                    ]
                },

            ]
    },

    duties: [

         ///////////////////////////////////////////////////////////////////////////////
        //          NA
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_NOT_APPLICABLE,
            fees: [],
            concessions: []
        },


        ///////////////////////////////////////////////////////////////////////////////
        //          ACT
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_ACT,
            fees: [
                {
                    name: "Stamp Duty",
                    conditions: [PRIMARY_RESIDENCE],
                    incremental: true,
                    quantise: 100,
                    nearest: 0.01,
                    // brackets: [
                    //     { from: 0, to: 200000, type: "percent", value: 0.68 },
                    //     { from: 200000, to: 300000, type: "percent", value: 2.2 },
                    //     { from: 300000, to: 500000, type: "percent", value: 3.4 },
                    //     { from: 500000, to: 750000, type: "percent", value: 4.32 },
                    //     { from: 750000, to: 1000000, type: "percent", value: 5.9 },
                    //     { from: 1000000, to: 1455000, type: "percent", value: 6.4 },
                    //     { from: 1455001, to: 0, type: "percent", value: 4.54, incremental: false },
                    // ]
                    brackets: [
                        { from: 0, to: 260000, type: "percent", value: 0.4 },
                        { from: 260000, to: 300000, type: "percent", value: 2.2 },
                        { from: 300000, to: 500000, type: "percent", value: 3.4 },
                        { from: 500000, to: 750000, type: "percent", value: 4.32 },
                        { from: 750000, to: 1000000, type: "percent", value: 5.9 },
                        { from: 1000000, to: 1455000, type: "percent", value: 6.4 },
                        { from: 1455001, to: 0, type: "percent", value: 4.54, incremental: false },
                    ]
                },
                {
                    name: "Stamp Duty",
                    exclusions: [PRIMARY_RESIDENCE],
                    incremental: true,
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 200000, type: "percent", value: 1.2 },
                        { from: 200000, to: 300000, type: "percent", value: 2.2 },
                        { from: 300000, to: 500000, type: "percent", value: 3.4 },
                        { from: 500000, to: 750000, type: "percent", value: 4.32 },
                        { from: 750000, to: 1000000, type: "percent", value: 5.9 },
                        { from: 1000000, to: 1455000, type: "percent", value: 6.4 },
                        { from: 1455001, to: 0, type: "percent", value: 4.54, incremental: false },
                    ]
                },
                {
                    name: "Mortgage Registration Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 172 },
                    ]
                },
                {
                    name: "Transfer Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 463 },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 31.00 },
                //     ]
                // },
            ],
            concessions: [
                {
                    name: "First Home Buyers Assistance",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 1000000, type:  "percent", reference: "Stamp Duty", value: 100 },
                        { from: 1000000, to: 0, type: "fixed", value: 35910},
                    ]
                },

                // {
                //     name: "First Home Owner Grant",
                //     incremental: false,
                //     conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, FIRST_HOME_OWNER_GRANT],
                //     exclusions: [],
                //     quantise: 100,
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 750000, type: "fixed", value: 10000 },
                //         { from: 750000, to: 0, type: "fixed", value: 0 },
                //     ]
                // },


            ]
        },


        ///////////////////////////////////////////////////////////////////////////////
        //          NSW
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_NSW,
            fees: [

                // stamp duty: https://www.apps09.revenue.nsw.gov.au/erevenue/calculators/landsalesimple.php
                {
                    name: "Stamp Duty",
                    incremental: true,
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 17000, type: "percent", value: 1.25 },
                        { from: 17001, to: 17001, type: "fixed", value: -0.5 },
                        { from: 17000, to: 36000, type: "percent", value: 1.5 },
                        { from: 36000, to: 97000, type: "percent", value: 1.75 },
                        { from: 97001, to: 97001, type: "fixed", value: -0.5 },
                        { from: 97000, to: 364000, type: "percent", value: 3.5 },
                        { from: 364000, to: 1212000, type: "percent", value: 4.5 },
                        { from: 1212000, to: 3636000, type: "percent", value: 5.5 },
                        { from: 3636000, to: 0, type: "percent", value: 7.0, incremental:true},
                    ]
                },
                {
                    name: "Mortgage Registration Fee",
                    nearest: 0.01,
                    brackets: [{ type: "fixed", value: 171.7 }]
                },
                {
                    name: "Transfer Fee",
                    nearest: 0.01,
                    brackets: [{ type: "fixed", value: 171.7 }]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 17.60 },
                //     ]
                // },


            ],
            concessions: [

                // reference: https://www.apps09.revenue.nsw.gov.au/erevenue/calculators/fhba.php

                {
                    name: "First Home Buyers Assistance (existing home)",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, {buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 800000, type: "percent", reference: "Stamp Duty", value: 100 },
                        { from: 800000, to: 1000000, type: "slope", m:  -0.152645, c: 152645},
                        { from: 1000001, to: 0, type: "fixed", value: 0 },
                    ]
                },


                {
                    name: "First Home Buyers Assistance (new home)",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, {buildingType: NEW_BUILDING}],
                    exclusions: ["landTax"],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 800000, type: "percent", reference: "Stamp Duty", value: 100 },
                        { from: 800000, to: 1000000, type: "slope", m:  -0.152645, c: 152645},
                        { from: 1000000, to: 0, type: "fixed", value: 0 },
                    ]
                },



                {
                    name: "First Home Buyers Assistance (land)",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, {buildingType: VACANT_LAND}],
                    exclusions: ["landTax"],
                    quantise: 100,
                    nearest: 0.01,
                    landValue:true,
                    
                    brackets: [
                        { from: 0, to: 350000, type: "percent", reference: "Stamp Duty", value: 100 },
                        { from: 350000, to: 450000, type: "slope", m: -0.10957, c: 49306.5},
                        { from: 450000, to: 0, type: "fixed", value: 0 },
                    ]
                },
                
                {
                    name: "First Home Owner Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, FIRST_HOME_OWNER_GRANT],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 750000, type: "fixed", value: 10000 },
                        { from: 750000, to: 0, type: "fixed", value: 0 },
                    ]
                },


            ]
        },


         ///////////////////////////////////////////////////////////////////////////////
        //          NT
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_NT,
            fees: [

                {
                    name: "Stamp Duty",
                    incremental: false,
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 525000, type: "quadratic", a: 0.06571441, b: 15, c:1000  },
                        { from: 525000, to: 3000000, type: "percent", value: 4.95 },
                        { from: 3000000, to: 5000000, type: "percent", value: 5.75 },
                        { from: 5000000, to: 0, type: "percent", value: 5.95 },
                    ]
                },

                {
                    name: "Mortage Registration Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 172 },
                    ]
                },
                {
                    name: "Transfer Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 172 },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 35.00 },
                //     ]
                // },
            ],
            concessions: [
                {
                    name: "Fresh Start New Home Grant",
                    incremental: false,
                    conditions: [PRIMARY_RESIDENCE, {buildingType: NEW_BUILDING}],
                    exclusions: [ FIRST_HOME_OWNER_GRANT],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 30000 },
                    ]
                },

                {
                    name: "HomeGrown Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER_GRANT, PRIMARY_RESIDENCE, {buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 10000 },
                    ]
                },

                {
                    name: "HomeGrown Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER_GRANT, PRIMARY_RESIDENCE, {buildingType: NEW_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 50000 },
                    ]
                },


            ]
        },



        ///////////////////////////////////////////////////////////////////////////////
        //         QLD
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_QLD,
            fees: [
                {
                    name: "Stamp Duty",
                    label:"Stamp Duty (Discounted)",
                    incremental: true,
                    conditions: [PRIMARY_RESIDENCE],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 350000, type: "percent", value: 1 },
                        { from: 350000, to: 540000, type: "percent", add:0, value: 3.5 },
                        { from: 540000, to: 1000000, type: "percent", add:0, value: 4.5},
                        { from: 1000001, to: 0, type: "percent", add:0, value: 5.75},
                    ]
                },
                {
                    name: "Stamp Duty",
                    label:"Stamp Duty (Full)",
                    incremental: true,
                    exclusions: [PRIMARY_RESIDENCE],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 5000, type: "fixed", value: 0 },
                        { from: 5000, to: 75000, type: "percent", value: 1.5 },
                        { from: 75000, to: 540000, type: "percent", add:0, value: 3.5 },
                        { from: 540000, to: 1000000, type: "percent", add:0, value: 4.5},
                        { from: 1000001, to: 0, type: "percent", add:0, value: 5.75},
                    ]
                },
                {
                    name: "Mortgage Registration Fee",
                    brackets: [{ type: "fixed", value: 231.98 }]
                },

                {
                    name: "Title transfer fee",
                    incremental: true,
                    quantise: 10000,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 180000, type: "fixed", value: 231.98 },
                        { from: 180000, to: 0, type: "percent", add:0, value: 0.4356},
                    ]
                },
                {
                    name: "Foreign Investment fee",
                    incremental: true,
                    conditions: [FOREIGN_INVESTOR],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "percent", value: 7 },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 20.45 },
                //     ]
                // },


            ],
            concessions: [


                {
                    name: "First Home Stamp Duty concession",
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE,],
                    exclusions: [{buildingType: VACANT_LAND}, FOREIGN_INVESTOR],
                    incremental: false,
                    quantise: 1,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 710000, type: "fixed", value: 17350},
                        { from: 710000, to: 720000, type: "fixed", value: 15615 },
                        { from: 720000, to: 730000, type: "fixed", value: 13880 },
                        { from: 730000, to: 740000, type: "fixed", value: 12145 },
                        { from: 740000, to: 750000, type: "fixed", value: 10410 },
                        { from: 750000, to: 760000, type: "fixed", value: 8675 },
                        { from: 760000, to: 770000, type: "fixed", value: 6940 },
                        { from: 770000, to: 780000, type: "fixed", value: 5205 },
                        { from: 780000, to: 790000, type: "fixed", value: 3470 },
                        { from: 790000, to: 800000, type: "fixed", value: 1735 },
                        { from: 800000, to: 0, type: "fixed", value: 0 },
                    ]
                },
                {
                    name: "First Home Stamp Duty concession (land)",
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE,{buildingType: VACANT_LAND}],
                    exclusions: [FOREIGN_INVESTOR],
                    incremental: false,
                    quantise: 1,
                    nearest: 0.01,
                    landValue:true,
                                        
                    brackets: [
                        //{ from: 0, to: 250000, type: "percent", reference: "Stamp Duty", value: 100 }, // concession of 100% of the "stamp duty" fee
                        
                        { from: 0, to: 505000, type: "fixed", value: 8750 },
                        { from: 505000, to: 510000, type: "fixed", value: 7875 },
                        { from: 510000, to: 515000, type: "fixed", value: 7000 },
                        { from: 515000, to: 520000, type: "fixed", value: 6125 },
                        { from: 520000, to: 525000, type: "fixed", value: 5250 },
                        { from: 525000, to: 530000, type: "fixed", value: 4375 },
                        { from: 530000, to: 535000, type: "fixed", value: 3500 },
                        { from: 535000, to: 540000, type: "fixed", value: 2625 },
                        { from: 540000, to: 545000, type: "fixed", value: 1750 },
                        { from: 545000, to: 550000, type: "fixed", value: 875 },
                        { from: 550000, to: 0, type: "fixed", value: 0 },
                    ]
                    
                },

                {
                    name: "First Home Owner Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, FIRST_HOME_OWNER_GRANT],
                    exclusions: [FOREIGN_INVESTOR, {buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 750000, type: "fixed", value: 30000 },
                        { from: 750000, to: 0, type: "fixed", value: 0 },
                    ]
                },

            ]
        },



        ///////////////////////////////////////////////////////////////////////////////
        //          SA
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_SA,
            fees: [
                {
                    name: "Stamp Duty",
                    incremental: true,
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 12000, type: "percent", value: 1  },
                        { from: 12000, to: 30000, type: "percent", value: 2 },
                        { from: 30000, to: 50000, type: "percent", value: 3 },
                        { from: 50000, to: 100000, type: "percent",  value: 3.5 },
                        { from: 100000, to: 200000, type: "percent", value: 4.0 },
                        { from: 200000, to: 250000, type: "percent", value: 4.25 },
                        { from: 250000, to: 300000, type: "percent", value: 4.75 },
                        { from: 300000, to: 500000, type: "percent", value: 5.0 },
                        { from: 500000, to: 0, type: "percent", value: 5.5 },
                    ]
                },

                {
                    name: "Lodgement Fee",
                    incremental: true,
                    quantise: 10000,
                    nearest: 0.1,
                    brackets: [
                        { from: 0, to: 5000, type: "fixed", value: 12, incremental: false  },
                        { from: 5000, to: 20000, type: "fixed", value: 215, incremental: false  },
                        { from: 20000, to: 40000, type: "fixed", value: 236, incremental: false },
                        { from: 40000, to: 50000, type: "fixed", value: 332, incremental: false  },
                        { from: 50000, to: 0, type: "percent", value: 0.99  },
                    ]
                },
                {
                    name: "Transfer Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 192 },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 31.25 },
                //     ]
                // },
            ],
            concessions: [
                {
                    name: "First Home Owner Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER_GRANT, PRIMARY_RESIDENCE],
                    exclusions: [{buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 575000, type: "fixed", value: 15000 },
                        { from: 575000, to: 0, type: "fixed", value: 0 },
                    ]
                },

                {
                    name: "Stamp Duty Relief",
                    incremental: true,
                    conditions: [FIRST_HOME_OWNER_GRANT, PRIMARY_RESIDENCE],
                    exclusions: [{buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 12000, type: "percent", value: 1  },
                        { from: 12000, to: 30000, type: "percent", value: 2 },
                        { from: 30000, to: 50000, type: "percent", value: 3 },
                        { from: 50000, to: 100000, type: "percent",  value: 3.5 },
                        { from: 100000, to: 200000, type: "percent", value: 4.0 },
                        { from: 200000, to: 250000, type: "percent", value: 4.25 },
                        { from: 250000, to: 300000, type: "percent", value: 4.75 },
                        { from: 300000, to: 500000, type: "percent", value: 5.0 },
                        { from: 500000, to: 0, type: "percent", value: 5.5 },
                    ]
                },


            ]
        },




        ///////////////////////////////////////////////////////////////////////////////
        //          WA
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_WA,
            fees: [

                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [ {buildingType: VACANT_LAND}, PRIMARY_RESIDENCE],
                    exclusions: [ FIRST_HOME_OWNER],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 120000, type: "percent", value: 1.9},
                        { from: 120000, to: 150000, type: "percent", value: 2.85 },
                        { from: 150000, to: 360000, type: "percent", value: 3.8 },
                        { from: 360000, to: 725000, type: "percent", value: 4.75 },
                        { from: 725000, to: 0, type: "percent", value: 5.15 },
                    ]
                },


                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [PRIMARY_RESIDENCE],
                    exclusions: [ {buildingType: VACANT_LAND}, FIRST_HOME_OWNER],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 120000, type: "percent", value: 1.9},
                        { from: 120000, to: 150000, type: "percent", value: 2.85 },
                        { from: 150000, to: 360000, type: "percent", value: 3.8 },
                        { from: 360000, to: 725000, type: "percent", value: 4.75 },
                        { from: 725000, to: 0, type: "percent", value: 5.15 },
                    ]
                },



                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [],
                    exclusions: [ PRIMARY_RESIDENCE],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 120000, type: "percent", value: 1.9},
                        { from: 120000, to: 150000, type: "percent", value: 2.85 },
                        { from: 150000, to: 360000, type: "percent", value: 3.8 },
                        { from: 360000, to: 725000, type: "percent", value: 4.75 },
                        { from: 725000, to: 0, type: "percent", value: 5.15 },
                    ]
                },


                 // First home owner - home
                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                    exclusions: [ {buildingType: VACANT_LAND}],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 430000, type: "fixed", value: 0},
                        { from: 430000, to: 530000, type: "percent", value: 19.19 },
                        { from: 530000, to: 725000, type: "percent", value: 4.75 },
                        { from: 725000, to: 0, type: "percent", value: 5.15 },
                    ]
                },

                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [FIRST_HOME_OWNER, {buildingType: VACANT_LAND}, PRIMARY_RESIDENCE ],
                    exclusions: [],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 300000, type: "fixed", value: 0},
                        { from: 300000, to: 400000, type: "percent", value: 13.01 },
                        { from: 400000, to: 725000, type: "percent", value: 4.75 },
                        { from: 725000, to: 0, type: "percent", value: 5.15 },
                    ]
                },

                {
                    name: "Mortage Registration Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 210.3 },
                    ]
                },
                {
                    name: "Land Transfer Fee",
                    nearest: 0.01,
                    quantise: 100000,
                    incremental: false,
                    brackets: [
                        { from: 0, to: 85000, type: "fixed", value: 210.3, incremental: false },
                        { from: 85000, to: 120000, type: "fixed", value: 220.3, incremental: false },
                        { from: 120000, to: 200000, type: "fixed", value: 240.3, incremental: false },
                        { from: 200000, to: 0, type: "percent", add:0, value: 0.02, incremental: true },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 27.2 },
                //     ]
                // },
            ],
            concessions: [
                {
                    name: "First Home Owner Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                   exclusions: [ {buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 750000, type: "fixed", value: 10000 },
                    ]
                },


            ]
        },


       
        ///////////////////////////////////////////////////////////////////////////////
        //          TAS
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_TAS,
            fees: [

                {
                    name: "Stamp Duty",
                    incremental: true,
                    conditions: [ ],
                    exclusions: [ ],
                    quantise: 100,
                    nearest: 1,
                    brackets: [
                        { from: 0, to: 3000, type: "fixed", value: 50},
                        { from: 3000, to: 25000, type: "percent", value: 1.75 },
                        { from: 25000, to: 75000, type: "percent", value: 2.25 },
                        { from: 75000, to: 200000, type: "percent", value: 3.5 },
                        { from: 200000, to: 375000, type: "percent", value: 4.0 },
                        { from: 375000, to: 725000, type: "percent", value: 4.25 },
                        { from: 725000, to: 0, type: "percent", value: 4.5 },
                    ]
                },


                {
                    name: "Mortage Registration Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 159.88 },
                    ]
                },
                {
                    name: "Transfer Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 244.97 },
                    ]
                },
                // {
                //     name: "Title Search",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 33 },
                //     ]
                // },
            ],
            concessions: [
                {
                    name: "First Home Buyers Assistance",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE, {buildingType: EXISTING_BUILDING}],
                    quantise: 100,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 500001, type:  "percent", reference: "Stamp Duty", value: 50 },
                        { from: 500001, to: 0, type: "fixed", value: 0},
                    ]
                },

                {
                    name: "First Home Owner Grant",
                    incremental: false,
                    conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                    exclusions: [ {buildingType: EXISTING_BUILDING}],
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 30000 },
                    ]
                },


            ]
        },


        ///////////////////////////////////////////////////////////////////////////////
        //          VIC
        ///////////////////////////////////////////////////////////////////////////////
        {
            state: STATE_VIC,
            fees: [
                {
                    name: "Stamp Duty",
                    conditions: [ PRIMARY_RESIDENCE],
                    exclusions: [ ],
                    incremental: true,
                    quantise: 1,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 25000, type: "percent", value: 1.4 },
                        { from: 25000, to: 130000, type: "percent", value: 2.4 },
                        { from: 130000, to: 440000, type: "percent", value: 5.0 },
                        { from: 440000, to: 550001, type: "percent", value: 6.0 },
                        { from: 550001, to: 960001, type: "percent", value: 6.0, add: 3100 },
                        { from: 960001, to: 2000000, type: "percent", value: 5.5, add: 130, round: "floor" },
                        { from: 2000000, to: 0, type: "percent", value: 6.5, round: "floor" },
                    ],

                },

                {
                    name: "Stamp Duty",
                    conditions: [ ],
                    exclusions: [ PRIMARY_RESIDENCE],
                    incremental: true,
                    quantise: 1,
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 25000, type: "percent", value: 1.4 },
                        { from: 25000, to: 130000, type: "percent", value: 2.4 },
                        { from: 130000, to: 960001, type: "percent", value: 6.0 },
                        { from: 960001, to: 2000000, type: "percent", value: 5.5, add: 130, round: "floor" },
                        { from: 2000000, to: 0, type: "percent", value: 6.5, round: "floor" },
                    ],

                },

                {
                    name: "Mortgage Registration Fee",
                    nearest: 0.01,
                    brackets: [
                        { from: 0, to: 0, type: "fixed", value: 131.9 }, // 119.7 //121.40
                    ]
                },

                {
                    name: "Transfer Fee",
                    quantise: 1000,
                    nearest: 0.01,
                    cap: 3619,
                    brackets: [
                        { from: 0, to: 0, type: "percent", value: 0.234, add: 109.9 }
                    ]
                },

                // {
                //     name: "Title Search Fee",
                //     nearest: 0.01,
                //     brackets: [
                //         { from: 0, to: 0, type: "fixed", value: 36.2 }, // 119.7 //121.40
                //     ]
                // },

            ],

            concessions: [

                    {
                        name: "First Home Owner Grant",
                        incremental: false,
                        conditions: [FIRST_HOME_OWNER, PRIMARY_RESIDENCE],
                       exclusions: [ {buildingType: EXISTING_BUILDING}],
                        quantise: 100,
                        nearest: 0.01,
                        brackets: [
                            { from: 0, to: 750000, type: "fixed", value: 10000 },
                        ]
                    },


                // {
                //     //https://exactly.loans/calculate-vic-stamp-duty/
                //     name: "First Home Buyer",
                //     conditions: [FIRST_HOME_OWNER],
                //     brackets: [
                //         { from: 0, to: 600000, type: "multiply", value: 1, reference: "Stamp Duty" },
                //         { from: 600000, to: 750000, type: "multiplySlope", m:  -1/150000, c: 5, reference: "Stamp Duty" },
                //         { from: 750000, to: 0, type: "multiply", value: 0, reference: "Stamp Duty"  },
                //     ]
                // },

                // {
                //     //https://exactly.loans/calculate-vic-stamp-duty/
                //     name: "Metropolitan stamp duty concession",
                //     incremental: false,
                //     conditions: ["metropolitanProperty", {buildingType: NEW_BUILDING}],
                //     brackets: [
                //         { from: 0, to: 1000000, type: "multiply", value: 0.5, reference: "Stamp Duty" },
                //         { from: 1000000, to: 0, type: "fixed", value: 0 },
                //     ]
                // },

                // {
                //     name: "Principal residence",
                //     conditions: [PRIMARY_RESIDENCE],
                //     incremental: false,
                //     brackets: [
                //         { from: 0, to: 130000, type: "percent", value: 0 },
                //         { from: 130000, to: 440000, type: "percent", value: 1 },
                //         { from: 440000, to: 550000, type: "fixed", value: 3100 },
                //         { from: 550000, to: 0, type: "fixed", value: 0 },
                //     ]
                // },
            ]

        },


    ],

    conveyancing: {
        rates: [
            { state: STATE_NSW, low: 1500, high: 3500 },
            { state: STATE_VIC, low: 1500, high: 3500 },
            { state: STATE_QLD, low: 1500, high: 3500 },
            { state: STATE_ACT, low: 1500, high: 3500 },
            { state: STATE_TAS, low: 1500, high: 3500 },
            { state: STATE_SA, low: 1500, high: 3500 },
            { state: STATE_NT, low: 1500, high: 3500 },
            { state: STATE_WA, llow: 1500, high: 3500 },
        ]

    }
}
